<template>
  <div :style="placeholderStyle">
    <div :class="{'ild-affix': isAffix}" :style="affixStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { getOffset } from 'src/_utils/'
export default {
  props: {
    offsetTop: Number, // 相对顶部位置
    offsetBottom: { // 相对底部位置
      type: Number,
      default: 0
    },
    zIndex: Number // 是否需要防止遮盖
  },
  data () {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      _offsetTop: Number,
      // eslint-disable-next-line vue/no-reserved-keys
      _offsetBottom: Number,
      prefix: 'ild-affix',
      isTop: true,
      isAffix: false
    }
  },
  computed: {
    placeholderStyle () {
      let zIndex = {}
      if (this.zIndex) {
        zIndex = {
          position: 'relative',
          zIndex: this.zIndex
        }
      }

      return this.isAffix ? Object.assign({}, {
        width: this.$el.offsetWidth + 'px',
        height: this.$el.offsetHeight + 'px'
      }, zIndex) : null
    },
    affixStyle () {
      let style = null
      if (this.isAffix) {
        style = {
          left: getOffset(this.$el).left + 'px',
          width: this.$el.offsetWidth + 'px'
        }
        if (this.isTop) {
          style.top = this._offsetTop + 'px'
        } else {
          style.bottom = this._offsetBottom + 'px'
        }
      }
      return style
    }
  },
  mounted () {
    this.$nextTick(() => {
      this._offsetTop = parseInt(this.offsetTop)
      this._offsetBottom = this.offsetBottom
      if (this._offsetTop === undefined) {
        if (this._offsetBottom === undefined) {
          this._offsetTop = 0
        } else {
          this.isTop = false
        }
      }
      document.addEventListener('scroll', this.handleScroll)
      document.addEventListener('resize', this.handleScroll)
    })
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('resize', this.handleScroll)
  },
  methods: {
    handleScroll () {
      let isAffix = false
      // const scrollTop = getScroll(true)
      // const fixedNode = this.$el.children[0]
      const elemOffset = this.$el.getBoundingClientRect()
      if (this.isTop) {
        if (elemOffset.top < this._offsetTop) {
          isAffix = true
        }
      } else {
        const clientH = document.documentElement.clientHeight
        if (clientH - elemOffset.bottom < this._offsetBottom) {
          isAffix = true
        }
      }
      this.isAffix = isAffix
    }
  }
}
</script>

<style type="text/scss" lang="scss">
  .ild-affix {
    position: fixed;
    z-index: 20;
  }
</style>
