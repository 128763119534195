<template>
  <div class="content container-fluid" ref="content">
    <div class="row">
      <div class="col-xs-7 col-xs-offset-2 content__title clearfix">
        <span>{{$t('content.title')}}</span>
        <div class="pull-right">
          <!--补写内容无更新内容按钮-->
          <button class="btn small secondary" @click="updateItems" v-if="$route.query.tab !== 'patch'">{{$t('content.buttonPatchUpdate')}}</button>
          <!--批量恢复，回收箱内显示-->
          <button class="btn small default" @click="batchActions({recoverAll: true})" v-if="!!$route.query.bin">{{$t('content.buttonPatchCover')}}</button>
          <!--批量删除-->
          <button class="btn small default" @click="batchActions({removeAll: true})" v-else>{{$t('content.buttonPatchDelete')}}</button>
        </div>
      </div>
    </div>
    <div class="row content__items">
      <div class="col-xs-7 col-xs-offset-2">
        <div class="has-text-centered" v-if="!dataLoaded">
          {{$t('content.tipLoading')}}
        </div>
        <template v-else>
          <template v-if="$route.query.bin">
            <template v-if="!deletedItems.length">
              <div class="has-text-centered">
                {{$t('content.tipEmptyRecover')}}
              </div>
            </template>
            <template v-else>
              <chapter-item v-for="item in deletedItems" :config="item"
                            :deleted="true"
                            @recover="recoverItem(item)"
                            :key="item.itemId">
              </chapter-item>
            </template>
          </template>
          <template v-else>
            <template v-if="currentItems.length">
              <chapter-item v-for="item in currentItems" :config="item" :saving="leaving"
                            @edit="editItem(item)" @delete="deleteItem(item)" @save="saveItem(...arguments, item.itemId)"
                            :active="activeItemId === item.itemId"
                            :key="item.itemId">
              </chapter-item>
            </template>
            <div class="has-text-centered hidden-month"  v-else-if="!currentItems.length || !deletedItems">
              <p><i class="fa fa-eye-slash text-xl" aria-hidden="true"></i></p>
              <p>{{$t('content.tipVisibleEmpty')}}</p>
              <button class="btn secondary" @click="$router.push({name: $route.name, query: Object.assign({}, $route.query, {bin: true})})">{{$t('content.buttonVisibleRecover')}}</button>
            </div>
            <div class="has-text-centered" v-else>
              {{$t('content.tipEmptyContent')}}
            </div>
          </template>
          <div class="has-text-centered has-text-grey" v-if="fetching">
            <i class="icon fa fa-spinner fa-spin"></i>
            <p>{{$t('content.tipLoadMore')}}</p>
          </div>
          <div class="has-text-centered" v-if="page > 2 && (page >= tolPages) && !fetching">
            {{$t('content.tipNoMore')}}
          </div>
        </template>
      </div>
      <!--菜单按钮-->
      <div class="col-sm-2 hidden-xs content__actions">
        <affix offsetTop="136" class="">
          <button class="btn primary" @click="routeBack">
            <i class="fa fa-check-square" aria-hidden="true"></i>
            <span>{{$t('content.buttonSave')}}</span>
          </button>
          <button class="btn plant" @click="$router.push({name: $route.name, query: Object.assign({}, $route.query, {bin: ''})})" v-if="$route.query.bin">
            {{$t('content.buttonEdit')}}
          </button>
          <button class="btn plant" @click="$router.push({name: $route.name, query: Object.assign({}, $route.query, {bin: true})})"  v-else>
            {{$t('content.buttonBin')}}
          </button>
        </affix>
      </div>
    </div>
  </div>
</template>

<script>
import ChapterItem from './ChapterItem.vue'
import { findIndex, orderBy } from 'lodash'
import api from 'src/api/'
import Affix from 'src/components/common/Affix'

export default {
  name: '',
  components: { ChapterItem, Affix },
  data () {
    return {
      dataLoaded: false,
      currentItems: [],
      deletedItems: [],
      activeItemId: '',
      hasMenu: false,
      fetching: false,
      page: 0,
      tolPages: 1,
      pageSize: 12,
      leaving: false
    }
  },
  computed: {
  },
  created () {
    this.fetchSocialChapter().then(data => {
      this.tolPages = data
    })
  },
  mounted () {
    const classList = document.getElementsByTagName('html')[0].classList
    classList.add('gray-bg')
    window.addEventListener('scroll', this.handleScroll)
    console.log('load')
  },
  beforeRouteLeave (to, from, next) {
    const classList = document.getElementsByTagName('html')[0].classList
    classList.remove('gray-bg')
    window.removeEventListener('scroll', this.handleScroll)
    const chat = document.querySelector('.fb_dialog')
    if (chat) {
      chat.style.display = 'inline'
    }
    if (this.activeItemId) {
      this.activeItemId = ''
      this.leaving = true

      this.$toast({
        message: this.$t('content.toastAutoSave')
      }).then(_ => {
        next()
      })
      return
    }
    next()
  },
  methods: {
    routeBack () {
      const query = Object.assign({}, this.$route.query)
      const { pages } = query
      delete query.scid
      delete query.bin
      if (pages) {
        this.$router.push({ name: 'pages', query })
        return
      }
      this.$router.push({ name: 'selection', query })
    },
    fetchSocialChapter () {
      if (this.page >= this.tolPages) {
        return
      }
      if (this.fetching) {
        return
      }
      this.fetching = true
      const { sid, scid } = this.$route.query
      this.page += 1
      return api.fetchSocialChapter(sid, scid, this.page, this.pageSize).then(data => {
        this.fetching = false
        const { currentItems, deletedItems, pages } = data
        this.currentItems = this.currentItems.concat(currentItems)
        if (!this.dataLoaded) {
          this.deletedItems = this.deletedItems.concat(deletedItems)
        }
        this.dataLoaded = true
        return pages
      })
    },
    editItem (item) {
      const { itemId } = item
      this.activeItemId = itemId
    },
    deleteItem (item) {
      this.activeItemId = ''
      const { itemId } = item
      const deleteIndex = findIndex(this.currentItems, { itemId })
      this.deletedItems.push(this.currentItems[deleteIndex])
      this.deletedItems = orderBy(this.deletedItems, ['postDate'], ['asc'])
      this.currentItems.splice(deleteIndex, 1)
      this.batchActions({ remove: [item.itemId] })
    },
    saveItem (data, itemId) {
      this.activeItemId = ''
      this.chapterContentActions({ itemId, data })
    },
    recoverItem (item) {
      const { itemId } = item
      const deleteIndex = findIndex(this.deletedItems, { itemId })
      this.currentItems.push(this.deletedItems[deleteIndex])
      this.currentItems = orderBy(this.currentItems, ['postDate'], ['asc'])
      this.deletedItems.splice(deleteIndex, 1)
      this.batchActions({ recover: [item.itemId] })
    },
    batchActions (config) {
      if (typeof config !== 'object') {
        console.error('参数错误，应当为对象')
        return
      }
      const { sid = '', scid = '', tab = '', pages } = this.$route.query
      if (tab !== 'pages') {
        config.type = tab || 'facebook'
      } else {
        config.type = pages
      }
      this.$toast({
        message: this.$t('content.toastHandling'),
        position: 'bottom'
      })
      api.handleChapterItem(sid, scid, config).then(data => {
        if (config.removeAll) {
          this.deletedItems = this.deletedItems.concat(this.currentItems)
          this.currentItems = []
        } else if (config.recoverAll) {
          this.currentItems = this.currentItems.concat(this.deletedItems)
          this.deletedItems = []
        }
        this.$toast({
          message: this.$t('content.toastSaveDone'),
          position: 'bottom'
        })
      })
    },
    chapterContentActions ({ itemId, data }) {
      const { sid = '', scid = '' } = this.$route.query
      api.handleItemContent(sid, scid, itemId, data).then(data => {
        this.$toast({
          message: this.$t('content.toastSaveDone'),
          position: 'bottom'
        })
      })
    },
    updateItems () {
      const { sid = '', scid = '', tab = 'facebook' } = this.$route.query
      api.updateItems(sid, scid, tab).then(data => {
        if (!data || data.errors) {
          return this.$toast({
            message: this.$t('content.toastUpdateFailed'),
            position: 'bottom'
          })
        }
        this.$toast({
          message: this.$t('content.toastUpdateDone'),
          position: 'bottom'
        })
      })
    },
    handleScroll (e) {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop + window.innerHeight + 200 >= document.body.clientHeight && !this.$route.query.bin) {
        this.fetchSocialChapter()
      }
    }
  }
}
</script>

<style type="text/scss" lang="scss">
  @import '../../../styles/layout/Content';
.content {
  text-align: left;
  @media (max-width: 767px) {
    padding: 0;
  }
}
</style>
